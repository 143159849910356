import { render, staticRenderFns } from "./productsItem.vue?vue&type=template&id=16ba909a&scoped=true"
import script from "./productsItem.vue?vue&type=script&lang=js"
export * from "./productsItem.vue?vue&type=script&lang=js"
import style0 from "./productsItem.vue?vue&type=style&index=0&id=16ba909a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16ba909a",
  null
  
)

export default component.exports