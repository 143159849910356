<!--
 * @Author: lbh
 * @Date: 2021-04-23 16:38:57
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-27 15:28:56
 * @Description: 產品
-->
<template>
  <div
    class="products t-body"
    :style="`min-height:${window.bodyH}px;`"
  >
    <selfHeaderN
      ad="products"
      child="products"
      :type="window.type"
    />
    <div
      class="s-body"
      :class="window.type=='1000' ? '' : 's-body_1'"
    >
      <selfBackground
        :backH="window.backH"
        :title="$t('productsPage.bigTitle')"
        :type="1"
        :image="getImgPath('pro.jpg')"
      />
      <!-- 具體產品 -->
      <productsItem
        :productsArr="productsArr"
        :windowW="window.w"
      />
    </div>
    <selfFooter :type="window.type" />
    <self-contact v-if="!isRicepon" />
  </div>
</template>
<script>
import selfHeaderN from "@/components/header/headerN";
import selfFooter from "@/components/footer/footer";
import selfBackground from "@/components/commo/background";
import productsItem from "@/components/commo/productsItem";
import util from "@/js/utils/util";
export default {
  components: {
    selfHeaderN,
    selfFooter,
    selfBackground,
    productsItem
  },
  data () {
    return {
      window: {
        type: '1000',
        bodyH: 0,
        backH: 0,
        w: 0
      },
      productsArr: [],
      isRicepon: true
    }
  },
  mounted () {
    util.setTitle(`${this.$t('productsPage.bigTitle')} - ${this.$t('companyName')}`);
    // 初始化窗口
    this.initWindow();
    // 監聽 窗口
    window.onresize = () => {
      // 1000 700
      this.initWindow();
    };
    let isRicepon = util.isRicepon();
    this.isRicepon = isRicepon;
    // 組裝數據
    let productsArr = [];

    if (isRicepon) {
      productsArr.push({ title: this.$t('productsPage.labelA.title'), content: this.$t('productsPage.labelA.desc'), img: util.getImgPath('pro01.jpg'), go: '/gspos' });
      productsArr.push({ title: this.$t('productsPage.labelB.title'), content: this.$t('productsPage.labelB.desc'), img: util.getImgPath('pro02.jpg'), go: '/gsa' });
      productsArr.push({ title: this.$t('productsPage.labelC.title'), content: this.$t('productsPage.labelC.desc'), img: util.getImgPath('pro03.jpg'), go: '/gsq' })
    } else {
      productsArr.push({ title: 'GSPOS餐飲管理系統', content: '餐飲管理功能齊全 介面排版靈活易用 輕鬆節省人力成本', img: util.getImgPath('pro01.jpg'), go: '/contact' });
      productsArr.push({ title: '雲端餐飲管理系統', content: '一個APP助你隨時隨地管理餐廳', img: util.getImgPath('pro02.jpg'), go: '/contact' });
    }

    this.productsArr = productsArr;
  },
  methods: {
    initWindow () {
      let W = util.W(), w = W.w, h = W.h;
      this.window.bodyH = h;
      this.window.backH = parseInt(w / 2.5);
      this.window.w = w;
      if (w < 700) {
        this.window.type = '600';
        this.window.backH = w / 0.7;
      } else if (w < 1000) {
        this.window.type = '900';
        this.window.backH = w / 0.7;
      } else {
        this.window.type = '1000';
      }
    },
    getImgPath (t) {
      return util.getImgPath(t);
    }
  }
}
</script>
<style lang="less" scoped>
.products {
  background-color: rgb(249, 249, 249);
  .s-body {
    padding-bottom: 70px;
  }
}
</style>
