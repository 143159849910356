<!--
 * @Author: lbh
 * @Date: 2021-09-23 14:31:27
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-27 12:07:02
 * @Description: 產品介紹
-->
<template>
  <div class="products-item-box">
    <!-- 具體產品 -->
    <div
      class="items"
      :class="window.class_"
      v-for="(item,index) in productsArr"
      :key="index"
      :style="`${check(index) ? `` : `flex-direction: row-reverse;`}`"
    >
      <div class="left">
        <div class="line theme_bg_color"></div>
        <div
          v-text="item.title"
          class="title"
        ></div>
        <div
          v-text="item.content"
          class="content"
        ></div>
        <sButton
          :text="item.btnText || $t('productsPage.btn')"
          :index="index"
          type="duck"
          :go="item.go"
        />
      </div>
      <div class="right">
        <img :src="item.img" />
      </div>
    </div>
  </div>
</template>

<script>
import util from "@/js/utils/util";
import sButton from "@/components/commo/button";
export default {
  name: "products-item",
  components: {
    sButton,
  },
  props: {
    windowW: {
      default () {
        return 1000;
      }
    },
    productsArr: {
      default () {
        return []
      }
    },
    // 1 表示第一張居右, 其他表示 居左
    type: {
      default () {
        return 1;
      }
    }
  },
  watch: {
    windowW () {
      this.initWindow();
    }
  },
  data () {
    return {
      window: {
        class_: ''
      }
    }
  },
  methods: {
    // 初始化
    initWindow () {
      let w = this.windowW;
      if (w < 700) {
        this.window.class_ = 'items-column'
        if (w < 500) {
          this.window.class_ = 'items-column_s'
        }
      } else if (w < 1000) {
        this.window.class_ = 'items-column'
      } else {
        this.window.class_ = '';
      }
    },
    check (index) {
      if (this.type == 1)
        return index % 2 == 0
      else
        return index % 2 != 0;
    }
  }
}
</script>

<style lang="less" scoped>
.products-item-box {
  .items-column_s {
    margin: 30px 20px !important;
  }
  .items-column {
    margin: 30px 10% !important;
  }
  .items-column,
  .items-column_s {
    flex-direction: column-reverse !important;
    align-items: center;
    .right {
      width: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 50px;
    }
    .left {
      margin-bottom: 40px;
      width: calc(100% - 40px) !important;
    }
  }
  .items {
    margin: 30px 10% 0;
    border-radius: 5px;
    display: flex;
    background-color: #fff;
    .left {
      width: calc(50% - 40px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      .line {
        margin-top: 25px;
        width: 135px;
        height: 3px;
        margin-bottom: 25px;
        transform: scale(1, 1);
        visibility: visible;
      }
      .title {
        font-size: 36px;
        font-weight: 400;
        text-align: center;
      }
      .content {
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        margin-top: 25px;
      }
    }
    .right {
      width: 50%;
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}
</style>